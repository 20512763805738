<template>
    <div class="booth-ad-content">
        <div class="header">时间流量解析</div>
        <div class="chart-tab">
         <div class="select-header">
            <el-checkbox-group v-model="checkList">
                <el-checkbox :label="checkItem" v-for="checkItem in categoryList" :key="checkItem"></el-checkbox>
            </el-checkbox-group>
<!--            <el-select v-model="selectedValue" placeholder="请选择时间" @change="selecttIime">-->
<!--                <el-option-->
<!--                v-for="item in selectOption"-->
<!--                :key="item.value"-->
<!--                :label="item.label"-->
<!--                :value="item.value">-->
<!--                </el-option>-->
<!--            </el-select>-->
         </div>
            <div class="data-content" id="boothAd"></div>
        </div>
        <div class="cut-off"></div>
        <div class="header area-header">
            流量解析
            <div class="focus-text">
                成交客户主集中在
                <span class="focus-city"
                              v-for="(customerItem, customerIndex) in customerList" :key="`customerList_${customerIndex}`">
                    {{customerItem}}<span v-if="customerIndex !== customerList.length - 1">、</span>
                </span>
            </div>
<!--            <div style="display: flex;justify-content: flex-start;align-items: center;margin-left: 10px;">-->
<!--                <div style="font-size: 12px; margin-right: 10px;">类目</div>-->
<!--                <el-select v-model="classValue" placeholder="请选择">-->
<!--                    <el-option-->
<!--                        v-for="(item, key) in options"-->
<!--                        :key="key"-->
<!--                        :label="item"-->
<!--                        :value="key">-->
<!--                    </el-option>-->
<!--                </el-select>-->
<!--            </div>-->
            <div style="flex: 1; height: 40px;display: flex;justify-content: flex-end;align-items: center;">
                <div style="font-size: 14px;color: red;">展位平均展现量：1863</div>
            </div>
        </div>
        <div class="area-table">
            <el-table :data="areaList" border style="width: 100%; margin-top: 10px; flex: 1" size="medium"
                      :header-cell-style="{fontWeight: 'normal', height: '48px', color: '#666666', background: '#F5F5F5'}"
                      :cell-style="{fontSize: '14px',color: '#343434'}">
                <el-table-column align="center" label="排名" width="60">
                    <template slot-scope="scope">
                        <div class="ranking" v-if="scope.row.sort === 1 || scope.row.sort === 2 || scope.row.sort === 3">{{scope.row.sort}}</div>
                        <div v-else style="line-height: 24px">{{scope.row.sort}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="name" align="center" label="省份"></el-table-column>
                <el-table-column align="center" label="点击率">
                    <template slot-scope="scope">
                        {{scope.row.click}}%
                    </template>
                </el-table-column>
                <el-table-column align="center" label="转化率">
                    <template slot-scope="scope">
                        {{scope.row.conversion}}%
                    </template>
                </el-table-column>
                <el-table-column align="left" label="占比">
                    <template slot-scope="scope">
                        <div class="area-item">
                            <el-progress :percentage="scope.row.percentage" :show-text="false"></el-progress>
                            <span class="percentage">{{scope.row.value}}%</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="operation-pagination" style="margin: 20px 0;"
                           :current-page="listPages.currentPageNum"
                           :page-size="listPages.eachPageNum"
                           :total="listPages.total"
                           layout="prev, pager, next, jumper"
                           @current-change="handleCurrentChange">
            </el-pagination>
               <!-- 放地图 -->
        <MapEcharts/>
        </div>
    </div>
</template>

<script>
import MapEcharts from './mapEcharts.vue'
    export default {
        name: "BoothAd",
        data() {
            return {
                classValue: 0,
                options: [
                    "数码",
                    "电脑，办公",
                    "家用电器",
                    "服饰内衣",
                    "运动户外",
                    "母婴",
                    "食品饮料",
                    "家具日用",
                    "礼品",
                    "图书",
                    "文娱",
                ],
                boothAd: null,
                checkList: ['展现次数', '点击率', '转化率'],
                categoryList: ['展现次数', '点击率', '转化率'], //'潜在买家指数','质量指数'
                allSeries: [
                {
                                name: '展现次数',
                                type: 'line',
                                data: [29692,14916,9054,28937,29496,14336,8061,24389,6255 ,17313 ,10249 ,27572 ,25838 ,13755 ,15152 ,29201,20340,10431 ,7775,24989 ,29569,28594 ,25361 ,14534 ],
                                yAxisIndex: 1,
                                smooth: true,
                                itemStyle: {
                                    normal: {
                                        color: "#E43A3C",
                                    }
                                },
                            },
                            {
                                name: '点击率',
                                type: 'line',
                                data: [17.40,5.03 ,8.02,8.34,9.70 ,7.67 ,18.63 ,11.73 ,	11.00,17.62,15.62,11.59,14.65,17.38,14.20,17.40,11.84,19.38,19.24,9.40,12.68 ,11.05,15.52 ,12.21 ],
                                smooth: true,
                                itemStyle: {
                                    normal: {
                                        color: "#26C6DA",
                                    }
                                },
                            },
                            {
                                name: '转化率',
                                type: 'line',
                                data: [13.50,5.48,7.07,12.20,11.46,6.01,14.83,4.89 ,10.78,14.31,11.84,7.56,4.98,10.50,6.39,10.82,14.02,13.98,11.64,11.04,7.06,7.29,7.65,12.79],
                                smooth: true,
                                itemStyle: {
                                    normal: {
                                        color: "#1E88E5",
                                    }
                                },
                            },
                            // {
                            //     name: '潜在买家指数',
                            //     type: 'line',
                            //     data: [91,183,79,23,85 ,81,175,128,181,40,51,24,160,71,72,47,141,153,12,40,56,56,83,60],
                            //     smooth: true,
                            //     itemStyle: {
                            //         normal: {
                            //             color: "#FEC635",
                            //         }
                            //     },
                            // },
                            // {
                            //     name: '质量指数',
                            //     type: 'line',
                            //     data: [5,40,78,82,70,17,92,62,56,95,21,76,40,69,67,49,20,59,32,77,32,79,19,72],
                            //     smooth: true,
                            //     itemStyle: {
                            //         normal: {
                            //             color: "#A055FF",
                            //         }
                            //     },
                            // }
                ],
                customerList: [],
                //地区分布
                areaList: [],
                //分页
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                denominator: null,
                oneData:[
                    {
                    name:'展现次数',
                    data:[29692,14916,9054,28937,29496,14336,8061,24389,6255 ,17313 ,10249 ,27572 ,25838 ,13755 ,15152 ,29201,20340,10431 ,7775,24989 ,29569,28594 ,25361 ,14534 ]
                    },
                    {
                        name:'点击率',
                        data:[17.40,5.03 ,8.02,8.34,9.70 ,7.67 ,18.63 ,11.73 ,	11.00,17.62,15.62,11.59,14.65,17.38,14.20,17.40,11.84,19.38,19.24,9.40,12.68 ,11.05,15.52 ,12.21 ]
                    },
                    {
                        name:'转化率',
                        data:[13.50,5.48,7.07,12.20,11.46,6.01,14.83,4.89 ,10.78,14.31,11.84,7.56,4.98,10.50,6.39,10.82,14.02,13.98,11.64,11.04,7.06,7.29,7.65,12.79]
                    },
                    {
                        name:'潜在买家指数',
                        data:[91,183,79,23,85 ,81,175,128,181,40,51,24,160,71,72,47,141,153,12,40,56,56,83,60]
                    },
                    {
                        name:'质量指数',
                        data:[5,40,78,82,70,17,92,62,56,95,21,76,40,69,67,49,20,59,32,77,32,79,19,72]
                    },
                ],
                twoData:[
                {
                    name:'展现次数',
                    data:[3457,3068,3923,3584,3779,3930,3653,4210,4966,4515,3668,3649,4028,3763,3858,3819,4289,4427,4857,4756,4308,4272,4877,4083]
                    },
                    {
                        name:'点击率',
                        data:[13.88,17.42,14.36,7.90,7.35,12.43,14.37,18.32,8.01,8.70,18.25,8.63,6.34,12.90,12.81,10.94,6.96,13.64,10.72,13.12,16.61,15.87,19.74,7.79]
                    },
                    {
                        name:'转化率',
                        data:[6.14,14.78,9.95,6.46,10.38,5.12,10.64,8.96,9.27,13.05,14.27,9.26,6.03,6.36,9.78,9.37,6.71,8.70,8.52,11.05,6.47,13.47,14.77,5.48]
                    },
                    {
                        name:'潜在买家指数',
                        data:[63,141,157,187,194,67,62,199,101,179,105,160,174,195,184,51,110,143,180,79,179,132,181,136]
                    },
                    {
                        name:'质量指数',
                        data:[27,85,53,3,86,52,70,62,31,51,29,37,17,80,25,9,6,66,37,57,14,65,32,40]
                    },
                ],
                selectOption:[
                    {
                        label:'周一',
                        value:1,
                    },
                    {
                        label:'周二',
                        value:2,
                    },
                    {
                        label:'周三',
                        value:3,
                    },
                    {
                        label:'周四',
                        value:4,
                    },
                    {
                        label:'周五',
                        value:5,
                    },
                    {
                        label:'周六',
                        value:6,
                    },
                    {
                        label:'周日',
                        value:7,
                    },
                ],
                selectedValue:void 0

            }
        },
        mounted(){
            this.getBoothAdInfo();
            this.getAreaList()
            this.boothAd = this.$echarts.init(document.getElementById('boothAd'));

            let resizeTimer = null;
            window.onresize = () => {
                clearTimeout(resizeTimer);
                resizeTimer = setTimeout(() => {
                    this.boothAd.resize()
                }, 100)
            }
        },
        computed: {
            series() {
                return this.allSeries.filter(item => {
                    return this.checkList.includes(item.name)
                })
            }
        },
        watch: {
            series(val) {
                this.getTrendECharts()
            }
        },
        methods: {
            // 获取eChart图表
            getBoothAdInfo() {
                this.$http.axiosGet(this.$api.zhanweiTrendAnalysis, (res) => {
                    if (res.code === 200) {
                        this.allSeries = [
                            {
                                name: '展现次数',
                                type: 'line',
                                data: res.data.show_num,
                                yAxisIndex: 1,
                                smooth: true,
                                itemStyle: {
                                    normal: {
                                        color: "#E43A3C",
                                    }
                                },
                            },
                            {
                                name: '点击率',
                                type: 'line',
                                data: res.data.click,
                                smooth: true,
                                itemStyle: {
                                    normal: {
                                        color: "#26C6DA",
                                    }
                                },
                            },
                            {
                                name: '转化率',
                                type: 'line',
                                data: res.data.conversion,
                                smooth: true,
                                itemStyle: {
                                    normal: {
                                        color: "#1E88E5",
                                    }
                                },
                            },
                            {
                                name: '潜在买家指数',
                                type: 'line',
                                data: [63,141,157,187,194,67,62,199,101,179,105,160,174,195,184,51,110,143,180,79,179,132,181,136],
                                smooth: true,
                                itemStyle: {
                                    normal: {
                                        color: "#FEC635",
                                    }
                                },
                            },
                            {
                                name: '质量指数',
                                type: 'line',
                                data: [5,40,78,82,70,17,92,62,56,95,21,76,40,69,67,49,20,59,32,77,32,79,19,72],
                                smooth: true,
                                itemStyle: {
                                    normal: {
                                        color: "#A055FF",
                                    }
                                },
                            }
                        ];
                        this.getTrendECharts()
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 趋势图
            getTrendECharts() {
                this.boothAd.setOption({
                    xAxis: {
                        type: 'category',
                        data: ['01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00',
                               '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00' ],
                        boundaryGap: false,
                        axisLabel: {
                            textStyle: {
                                color: '#666'
                            },
                            align: 'center'
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                    },
                    yAxis: [{
                        type: 'value',
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: '#666'
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        }
                    }, {
                        type: 'value',
                        position: 'right',
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: '#000'
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        }
                    }],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985'
                            }
                        },
                        backgroundColor: '#fff',
                        textStyle: {
                            color: '#666',
                        },
                        borderColor: '#DCDCDC',
                        borderWidth: 2,
                        formatter: (params) => {
                            let res = params[0].name;
                            for (let i = 0; i < params.length; i++) {
                                if (params[i].seriesName === '点击率' || params[i].seriesName === '转化率') {
                                    res += '<br/>' + params[i].marker + params[i].seriesName + ' : ' + params[i].value + '%';
                                } else {
                                    res += '<br/>' + params[i].marker + params[i].seriesName + ' : ' + params[i].value;
                                }
                            }
                            return res
                        }
                    },
                    legend: {
                        icon: 'circle',
                        // data: this.checkList,
                        bottom: 0,
                        selectedMode: false
                    },
                    grid: {
                        top: 20,
                        left: 80,
                        right: '5%',
                        bottom: 80,
                    },
                    series: this.series
                }, true);
            },
            // 获取地区分布数据
            getAreaList() {
                let params = {
                    page: this.listPages.currentPageNum,
                    limit: this.listPages.eachPageNum
                }
                this.$http.axiosGetBy(this.$api.zhanweiAreaAnalysis, params, (res) => {
                    if (res.code === 200) {
                        if (this.listPages.currentPageNum === 1) {
                            this.denominator = res.data.list[0].percentage;
                        }
                        this.customerList = res.data.maximum_city

                        let tmpArr = [];
                        for (let i in res.data.list) {
                            let obj = {
                                click: res.data.list[i].click,
                                conversion: res.data.list[i].conversion,
                                name: res.data.list[i].name,
                                sort: res.data.list[i].sort,
                                percentage: Number((res.data.list[i].percentage / this.denominator * 100 ).toFixed(2)),
                                value: Number((res.data.list[i].percentage))
                            };
                            tmpArr.push(obj);
                        }
                        this.areaList = tmpArr;

                        this.listPages.total = res.data.total
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            //分页
            handleCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getAreaList()
            },
            selecttIime(){
                if(this.selectedValue%2==1){
                   this.allSeries=[
                {
                                name: '展现次数',
                                type: 'line',
                                data: [29692,14916,9054,28937,29496,14336,8061,24389,6255 ,17313 ,10249 ,27572 ,25838 ,13755 ,15152 ,29201,20340,10431 ,7775,24989 ,29569,28594 ,25361 ,14534 ],
                                yAxisIndex: 1,
                                smooth: true,
                                itemStyle: {
                                    normal: {
                                        color: "#E43A3C",
                                    }
                                },
                            },
                            {
                                name: '点击率',
                                type: 'line',
                                data: [17.40,5.03 ,8.02,8.34,9.70 ,7.67 ,18.63 ,11.73 ,	11.00,17.62,15.62,11.59,14.65,17.38,14.20,17.40,11.84,19.38,19.24,9.40,12.68 ,11.05,15.52 ,12.21 ],
                                smooth: true,
                                itemStyle: {
                                    normal: {
                                        color: "#26C6DA",
                                    }
                                },
                            },
                            {
                                name: '转化率',
                                type: 'line',
                                data: [13.50,5.48,7.07,12.20,11.46,6.01,14.83,4.89 ,10.78,14.31,11.84,7.56,4.98,10.50,6.39,10.82,14.02,13.98,11.64,11.04,7.06,7.29,7.65,12.79],
                                smooth: true,
                                itemStyle: {
                                    normal: {
                                        color: "#1E88E5",
                                    }
                                },
                            },
                            {
                                name: '潜在买家指数',
                                type: 'line',
                                data: [91,183,79,23,85 ,81,175,128,181,40,51,24,160,71,72,47,141,153,12,40,56,56,83,60],
                                smooth: true,
                                itemStyle: {
                                    normal: {
                                        color: "#FEC635",
                                    }
                                },
                            },
                            {
                                name: '质量指数',
                                type: 'line',
                                data: [5,40,78,82,70,17,92,62,56,95,21,76,40,69,67,49,20,59,32,77,32,79,19,72],
                                smooth: true,
                                itemStyle: {
                                    normal: {
                                        color: "#A055FF",
                                    }
                                },
                            }
                ]
                }else{
                    this.allSeries=[
                {
                                name: '展现次数',
                                type: 'line',
                                data:[3457,3068,3923,3584,3779,3930,3653,4210,4966,4515,3668,3649,4028,3763,3858,3819,4289,4427,4857,4756,4308,4272,4877,4083],
                                yAxisIndex: 1,
                                smooth: true,
                                itemStyle: {
                                    normal: {
                                        color: "#E43A3C",
                                    }
                                },
                            },
                            {
                                name: '点击率',
                                type: 'line',
                                data:[13.88,17.42,14.36,7.90,7.35,12.43,14.37,18.32,8.01,8.70,18.25,8.63,6.34,12.90,12.81,10.94,6.96,13.64,10.72,13.12,16.61,15.87,19.74,7.79] ,
                                smooth: true,
                                itemStyle: {
                                    normal: {
                                        color: "#26C6DA",
                                    }
                                },
                            },
                            {
                                name: '转化率',
                                type: 'line',
                                data: [6.14,14.78,9.95,6.46,10.38,5.12,10.64,8.96,9.27,13.05,14.27,9.26,6.03,6.36,9.78,9.37,6.71,8.70,8.52,11.05,6.47,13.47,14.77,5.48],
                                smooth: true,
                                itemStyle: {
                                    normal: {
                                        color: "#1E88E5",
                                    }
                                },
                            },
                            {
                                name: '潜在买家指数',
                                type: 'line',
                                data: [63,141,157,187,194,67,62,199,101,179,105,160,174,195,184,51,110,143,180,79,179,132,181,136],
                                smooth: true,
                                itemStyle: {
                                    normal: {
                                        color: "#FEC635",
                                    }
                                },
                            },
                            {
                                name: '质量指数',
                                type: 'line',
                                data: [27,85,53,3,86,52,70,62,31,51,29,37,17,80,25,9,6,66,37,57,14,65,32,40],
                                smooth: true,
                                itemStyle: {
                                    normal: {
                                        color: "#A055FF",
                                    }
                                },
                            }
                ]
                }

            }
        },
        components:{
            MapEcharts
        }
    }
</script>

<style scoped lang="scss">
    .booth-ad-content {
        /*margin: 0 20px;*/
        .header {
            margin: 0 20px;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #343434;
        }
        .area-header {
            display: flex;
            align-items: center;
            margin: 20px;
            .focus-text {
                font-size: 14px;
                color: #343434;
                margin-left: 13px;
                font-weight: 400;
                .focus-city {
                    font-size: 14px;
                    color: #FF0000;
                }
            }
        }
        .chart-tab {
            margin: 20px 20px 15px;
            display: flex;
            flex-direction: column;
            .select-header{
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .data-content {
                height: 330px;
                margin-top: 30px;
            }
            // 多选框的颜色
            ::v-deep .el-checkbox-group {
                .el-checkbox {
                    .el-checkbox__input {
                        .el-checkbox__inner {
                            &:hover {
                                border-color: #FD4446;
                            }
                        }
                        &.is-focus {
                            .el-checkbox__inner {
                                border-color: #FD4446;
                            }
                        }
                    }
                    &.is-checked {
                        .el-checkbox__input {
                            &.is-checked {
                                .el-checkbox__inner {
                                    background-color: #FD4446;
                                    border-color: #FD4446;
                                }
                            }
                            &:hover {
                                border-color: #FD4446;
                            }
                        }
                        .el-checkbox__label {
                            color: #343434;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
        .cut-off{
            width: 100%;
            height: 20px;
            background: #F7F9FB;
        }
        .area-table {
            margin: 0 20px;
            ::v-deep .el-table {
                .el-table__body-wrapper {
                    .el-table__row {
                        .ranking {
                            width: 24px;
                            height: 24px;
                            line-height: 24px;
                            text-align: center;
                            font-weight: 500;
                            background-size: cover;
                            display: inline-block;
                        }
                        &:first-child {
                            .ranking {
                                background: url("../../../../../assets/images/dataCenter/one.png") no-repeat center;
                            }
                        }
                        &:nth-of-type(2) {
                            .ranking {
                                background: url("../../../../../assets/images/dataCenter/two.png") no-repeat center;
                            }
                        }
                        &:nth-of-type(3) {
                            .ranking {
                                background: url("../../../../../assets/images/dataCenter/three.png") no-repeat center;
                            }
                        }
                    }
                }
            }
        }
    }
    .area-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .percentage {
            width: 40px;
            text-align: right;
        }
        ::v-deep .el-progress {
            width: 1%;
            flex: 1;
            padding: 0 10px;
        }
    }


    ::v-deep .el-select .el-input.is-focus .el-input__inner{
        border-color: #FD4446;
    }
    ::v-deep .el-select .el-input__inner:focus{
        border-color: #FD4446;
    }
    ::v-deep .el-select-dropdown__item.selected{
        color:#FD4446;
    }
    ::v-deep .el-input__inner{
        width: 150px;
    }
</style>
