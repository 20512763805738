<template>
    <div id="china_map_box">
        <div id="china_map" ref="mapDiv" style="width: 1900px; height:800px"></div>
    </div>
  </template>

  <script>
  import echarts from "echarts";
  import 'echarts/map/js/china.js'
  export default {
    data() {
      return {
        //echart 配制option
        options: {
          tooltip: {
            triggerOn: "mousemove",   //mousemove、click
            padding:8,
            borderWidth:1,
            borderColor:'#409eff',
            backgroundColor:'rgba(255,255,255,0.7)',
            textStyle:{
              color:'#000000',
              fontSize:13
            },
            formatter: function(e, t, n) {
              let data = e.data;
              //模拟数据
              data.specialImportant = Math.random()*1000 | 0;
              data.import = Math.random()*1000 | 0;
              data.compare = Math.random()*1000 | 0;
              data.common = Math.random()*1000 | 0;
              data.specail = Math.random()*1000 | 0;

              let context = `
                 <div class="content">
                     <p><b style="font-size:15px;">省份:${data.name}</p>
                     <p class="tooltip_style"><span class="tooltip_left">潜在买家指数:</span><span class="tooltip_right">${data.value}</span></p>
                     <p class="tooltip_style"><span class="tooltip_left">质量指数:</span><span class="tooltip_right">${data.specialImportant}</span></p>
                     <p class="tooltip_style"><span class="tooltip_left">占比:</span><span class="tooltip_right">7.6%</span></p>
                 </div>
              `
              return context;
            }
          },
          visualMap: {
            show:true,
            left: 26,
            bottom: 40,
            showLabel:true,
            pieces: [
              {
                gte: 100,
                label: ">= 1000",
                color: "#9cc2ff"
              },
              {
                gte: 500,
                lt: 999,
                label: "500 - 999",
                color: "#3c57ce"
              },
              {
                gte: 100,
                lt:499,
                label: "100 - 499",
                color: "#6f83db"
              },
              {
                gte: 10,
                lt: 99,
                label: "10 - 99",
                color: "#9face7"
              },
              {
                lt:10,
                label:'<10',
                color: "#bcc5ee"
              }
            ]
          },
          geo: {
            map: "china",
            scaleLimit: {
              min: 1,
              max: 2
            },
            zoom: 1,
            top: 120,
            label: {
              normal: {
                show:false,
                fontSize: "14",
                color: "rgba(0,0,0,0.7)"
              }
            },
            itemStyle: {
              normal: {
                //shadowBlur: 50,
                //shadowColor: 'rgba(0, 0, 0, 0.2)',
                borderColor: "rgba(0, 0, 0, 0.2)"
              },
              emphasis: {
                areaColor: "#0955ce",
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                borderWidth: 0
              }
            }
          },
          series: [
            {
              name: "突发事件",
              type: "map",
              geoIndex: 0,
              data:[]
            }
          ]
        },
        //echart data
        dataList: [
          {
            name: "南海诸岛",
            value: 2456,
            eventTotal:100,
            specialImportant:1252,
            import:4,
          },
          {
            name: "北京",
            value: 2456,
            eventTotal:100,
            specialImportant:1252,
            import:4,
          },
          {
            name: "天津",
            value: 2456,
            eventTotal:100,
            specialImportant:1252,
            import:4,
          },
          {
            name: "上海",
            value: 2456,
            eventTotal:100,
            specialImportant:1252,
            import:4,
          },
          {
            name: "重庆",
            value: 2456,
            eventTotal:100,
            specialImportant:1252,
            import:4,
          },
          {
            name: "河北",
            value: 2456,
            eventTotal:100,
            specialImportant:1252,
            import:4,
          },
          {
            name: "河南",
            value: 2456,
            eventTotal:100,
            specialImportant:1252,
            import:4,
          },
          {
            name: "云南",
            value: 2456,
            eventTotal:100,
            specialImportant:1252,
            import:4,
          },
          {
            name: "辽宁",
            value: 2456,
            eventTotal:100,
            specialImportant:1252,
            import:4,
          },
          {
            name: "黑龙江",
            value: 2456,
            eventTotal:100,
            specialImportant:1252,
            import:4,
          },
          {
            name: "湖南",
            value: 2456,
            eventTotal:100,
            specialImportant:1252,
            import:4,
          },
          {
            name: "安徽",
            value: 2456,
            eventTotal:100,
            specialImportant:1252,
            import:4,
          },
          {
            name: "山东",
            value: 2456,
            eventTotal:100,
            specialImportant:1252,
            import:4,
          },
          {
            name: "新疆",
            value: 2456,
            eventTotal:100,
            specialImportant:1252,
            import:4,
          },
          {
            name: "江苏",
            value: 2456,
            eventTotal:100,
            specialImportant:1252,
            import:4,
          },
          {
            name: "浙江",
            value: 2456,
            eventTotal:100,
            specialImportant:1252,
            import:4,
          },
          {
            name: "江西",
            value: 2456,
            eventTotal:100,
            specialImportant:1252,
            import:4,
          },
          {
            name: "湖北",
            value: 2456,
            eventTotal:100,
            specialImportant:1252,
            import:4,
          },
          {
            name: "广西",
            value: 2456,
            eventTotal:100,
            specialImportant:1252,
            import:4,
          },
          {
            name: "甘肃",
            value: 2456,
            eventTotal:100,
            specialImportant:1252,
            import:4,
          },
          {
            name: "山西",
            value: 2456,
            eventTotal:100,
            specialImportant:1252,
            import:4,
          },
          {
            name: "内蒙古",
            value: 2456,
            eventTotal:100,
            specialImportant:1252,
            import:4,
          },
          {
            name: "陕西",
            value: 2456,
            eventTotal:100,
            specialImportant:1252,
            import:4,
          },
          {
            name: "吉林",
            value: 2456,
            eventTotal:100,
            specialImportant:1252,
            import:4,
          },
          {
            name: "福建",
            value: 2456,
            eventTotal:100,
            specialImportant:1252,
            import:4,
          },
          {
            name: "贵州",
            value: 2456,
            eventTotal:100,
            specialImportant:1252,
            import:4,
          },
          {
            name: "广东",
            value: 2456,
            eventTotal:100,
            specialImportant:1252,
            import:4,
          },
          {
            name: "青海",
            value: 2456,
            eventTotal:100,
            specialImportant:1252,
            import:4,
          },
          {
            name: "西藏",
            value: 2456,
            eventTotal:100,
            specialImportant:1252,
            import:4,
          },
          {
            name: "四川",
            value: 2456,
            eventTotal:100,
            specialImportant:1252,
            import:4,
          },
          {
            name: "宁夏",
            value: 2456,
            eventTotal:100,
            specialImportant:1252,
            import:4,
          },
          {
            name: "海南",
            value: 2456,
            eventTotal:100,
            specialImportant:1252,
            import:4,
          },
          {
            name: "台湾",
            value: 2456,
            eventTotal:100,
            specialImportant:1252,
            import:4,
          },
          {
            name: "香港",
            value: 2456,
            eventTotal:100,
            specialImportant:1252,
            import:4,
          },
          {
            name: "澳门",
            value: 2456,
            eventTotal:100,
            specialImportant:1252,
            import:4,
          }
        ]
      };
    },
    methods: {
      //初始化中国地图
      initEchartMap() {
        let mapDiv = document.getElementById('china_map');
        let myChart = echarts.init(this.$refs.mapDiv);
        myChart.setOption(this.options);
      },
      //修改echart配制
      setEchartOption(){
         this.options.series[0]['data'] = this.dataList;
      }
    },
    created() {
      this.setEchartOption();
    },
    mounted() {
        this.$nextTick(()=>{
            this.initEchartMap();
        })

    },

  };
  </script>

  <style scoped>
      #china_map_box {
          height: 100%;
          position: relative;
      }
      #china_map_box #china_map{
          height: 100%;
      }
       #china_map_box .china_map_logo{
          position: absolute;
          top: 0;
          left: 0;
          width:45px;
       }
  </style>
  <style>
    #china_map .tooltip_style{

        overflow: hidden;

    }
    #china_map .tooltip_left{
        float: left;

    }
    #china_map .tooltip_right{
        float: right;
        margin-left: 5px;
    }
    #china_map .content{
        /* width: 200px; */
        padding: 0 10px;

        border: none;

    }

  </style>
